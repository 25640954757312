import * as React from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';


const FAQData = [{
        question: "What is the Covid-19 Policy?",
        answer: () => <>The safety of our athletes is of paramount importance to us. As such we strictly follow 
        <a href="http://www.chrvajuniors.org/chrva/docs/4-21%20UPDATE%20to%202020-2021%20CHRVA%20Participation%20Position%20Statement.pdf?fbclid=IwAR1AAzNYOZ9J4PhKLA6Lj-HxIrFQPnSCjg6VfrJvYRXzByYWp3hBhe15F5k" target="_blank"> CHRVA’s Covid-19 policy. </a> At this time, players are not required to wear masks for outdoor activities.
        We require all players to fill out a <Link to="/forms"> Covid Questionnaire </Link> before attending a clinic.</>
    },
    {
        question: "Are parents allowed to watch?",
        answer: () => "Yes! Parents are encouraged to stay and watch. However, if social distancing is not possible, parents / spectators must wear masks."
    },
    {
        question: "What is the inclement weather policy?",
        answer: () => "If a clinic or lesson gets canceled due to rain or other inclement weather, we have make up clinic dates available. If your clinic is canceled due to weather, you will be contacted to attend the make-up date."
    },
    {
        question: "What should I bring to the clinics?",
        answer: () => <>If you are attending your first clinic with us, you will need to bring a copy of your <a href="https://usavolleyball.org/membership/" target="_blank">USAV membership </a> 
        and your <a href="https://cdn1.sportngin.com/attachments/document/0061/4249/2020_Player_Medical_Release_w_ClubTeam_WITHOUT_Notary_fillable.pdf?fbclid=IwAR3wNzM8cUpczCLTvkaxGbg2RpTUEtpAmFE_ai25UqGstfNbTwsIXUjsJrg#_ga=2.30635641.773926524.1621193294-725735853.1615691333" target="_blank">USAV medical release form</a>. 
        Other than that, bring plenty of water, a towel, and a positive attitude!</>
    },
    {
        question: "What should I expect at the clinics?",
        answer: () => <>Clinics will be broken down into sections- a skill section and a play section. 
        Skill sections will focus on developing basic skills like passing, hitting, serving, etc and drills will be catered to the level of play. 
        So, you can expect more difficult and intense drills at a high school clinic vs a middle school clinic. 
        Play sections will provide players the opportunity to use their skills in game-play settings, whether that is playing doubles games or more involved game-play drills. 
        High school clinics will also have a conditioning section for players to get to that next level. Conditioning activities will focus on jumping, sprinting, and other movement on the court.</>
    },
    {
        question: "What is Open Gym and when is it?",
        answer: () => <>Open gym is an opportunity for players to come and play pick up games facilitated by the Blue Crabs Volleyball<Link to="/coaches">coaches</Link>. 
        These are available on a limited basis and dates will be posted on our calendar. It is important to still sign up online for open gyms so we know who to expect!</>
    },
    {
        question: "What forms do I need to participate?",
        answer: () => <>After you sign up for a clinic through our online form, you will receive an email indicating you need to bring your 
        <a href="https://usavolleyball.org/membership/" target="_blank">USAV membership </a> and your <a href="https://cdn1.sportngin.com/attachments/document/0061/4249/2020_Player_Medical_Release_w_ClubTeam_WITHOUT_Notary_fillable.pdf?fbclid=IwAR3wNzM8cUpczCLTvkaxGbg2RpTUEtpAmFE_ai25UqGstfNbTwsIXUjsJrg#_ga=2.30635641.773926524.1621193294-725735853.1615691333" target="_blank">USAV medical release form</a> 
        to the clinic with you. You only need to do this if it is your first clinic with us! 
        We must have copies of these for each player or you <u>cannot participate.</u> Before every clinic, players must also fill out a <a href="https://forms.gle/vHUJPCWhfCkdTLwQ8" target="_blank">Covid 19 Questionaire</a></>
    },
    {
        question: "Why should I play beach volleyball?",
        answer: () => "Beach volleyball is the fastest growing sport in the USA! This game is for everyone at all levels of play, from pickup with friends to USAV Nationals and beyond. If you are looking to play volleyball in college, playing beach can provide additional playing opportunities at higher levels. Even if you are unsure about playing outdoors, skills on the beach can be translated indoors, as playing beach can improve passing, hitting, serving, and other skills. Plus, it's also just a great way to spend some time outdoors!"
    },
    {
        question: "What is CHRVA?",
        answer: () => <>CHRVA is the Chesapeake Region Volleyball Association and is part of USA Volleyball. 
        More information about CHRVA is available <a href="https://www.chrva.org/" target="_blank">here</a>. Our club is affiliated with CHRVA in order to provide
        the best experience possible for our athletes in clinics and tournaments!</>
    },
    {
        question: "What is AVCA?",
        answer: () => <>The American Volleyball Coaches Association <a href="https://www.avca.org/" target="_blank">(AVCA)</a> is an organization dedicated to the providing resources and training to both coaches
        and athletes in order to elevate the volleyball experience. By being affiliated with AVCA, we can leverage a vast number of resources to help our players achieve their goals
        and in particular, facilitate and improve the college recruiting process.</>
    },
    {
        question: "Do you provide refunds if I can't make my clinic?",
        answer: () => "If you are unable to make your clinic, please contact us. We understand we are living in a time with a lot of circumstances out of our control, so we will work with people on a case by case basis."
    }


]

const structuredData = `{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is the Covid-19 Policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>The safety of our athletes is of paramount importance to us. As such we strictly follow <a href='http://www.chrvajuniors.org/chrva/docs/4-21%20UPDATE%20to%202020-2021%20CHRVA%20Participation%20Position%20Statement.pdf?fbclid=IwAR1AAzNYOZ9J4PhKLA6Lj-HxIrFQPnSCjg6VfrJvYRXzByYWp3hBhe15F5k' target='_blank'> CHRVA’s Covid-19 policy. </a> At this time, players are not required to wear masks for outdoor activities. We require all players to fill out a <a href='https://bluecrabsvolleyball.com/forms'> Covid Questionnaire </a> before attending a clinic.</p>"
      }
    }, {
      "@type": "Question",
      "name": "Are parents allowed to watch?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>Yes! Parents are encouraged to stay and watch. However, if social distancing is not possible, parents / spectators must wear masks.</p>"
      }
    }, {
      "@type": "Question",
      "name": "What is the inclement weather policy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>If a clinic or lesson gets canceled due to rain or other inclement weather, we have make up clinic dates available. If your clinic is canceled due to weather, you will be contacted to attend the make-up date.</p>"
      }
    }, {
      "@type": "Question",
      "name": "What should I bring to the clinics?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "<p>If you are attending your first clinic with us, you will need to bring a copy of your <a href='https://usavolleyball.org/membership/' target='_blank'>USAV membership </a> and your <a href='https://cdn1.sportngin.com/attachments/document/0061/4249/2020_Player_Medical_Release_w_ClubTeam_WITHOUT_Notary_fillable.pdf?fbclid=IwAR3wNzM8cUpczCLTvkaxGbg2RpTUEtpAmFE_ai25UqGstfNbTwsIXUjsJrg#_ga=2.30635641.773926524.1621193294-725735853.1615691333' target='_blank'>USAV medical release form</a>. Other than that, bring plenty of water, a towel, and a positive attitude!</p>"
      }
    }, {
      "@type": "Question",
      "name": "What is Open Gym and when is it?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text":"<p>Open gym is an opportunity for players to come and play pick up games facilitated by the Blue Crabs Volleyball<a href='https://bluecrabsvolleyball.com/coaches'>coaches</a>. These are available on a limited basis and dates will be posted on our calendar. It is important to still sign up online for open gyms so we know who to expect!</p>"
      }
    }, {
        "@type": "Question",
        "name": "What forms do I need to participate?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text":"<p>After you sign up for a clinic through our online form, you will receive an email indicating you need to bring your <a href='https://usavolleyball.org/membership/' target='_blank'>USAV membership </a> and your <a href='https://cdn1.sportngin.com/attachments/document/0061/4249/2020_Player_Medical_Release_w_ClubTeam_WITHOUT_Notary_fillable.pdf?fbclid=IwAR3wNzM8cUpczCLTvkaxGbg2RpTUEtpAmFE_ai25UqGstfNbTwsIXUjsJrg#_ga=2.30635641.773926524.1621193294-725735853.1615691333' target='_blank'>USAV medical release form</a> to the clinic with you. You only need to do this if it is your first clinic with us! We must have copies of these for each player or you <u>cannot participate.</u> Before every clinic, players must also fill out a <a href='https://forms.gle/vHUJPCWhfCkdTLwQ8' target='_blank'>Covid 19 Questionaire</a></p>"
        }
      }, {
        "@type": "Question",
        "name": "Why should I play beach volleyball?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text":"<p>Beach volleyball is the fastest growing sport in the USA! This game is for everyone at all levels of play, from pickup with friends to USAV Nationals and beyond. If you are looking to play volleyball in college, playing beach can provide additional playing opportunities at higher levels. Even if you are unsure about playing outdoors, skills on the beach can be translated indoors, as playing beach can improve passing, hitting, serving, and other skills. Plus, it's also just a great way to spend some time outdoors!</p>"
        }
      }, {
        "@type": "Question",
        "name": "What is CHRVA?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text":"<p>CHRVA is the Chesapeake Region Volleyball Association and is part of USA Volleyball. More information about CHRVA is available <a href='https://www.chrva.org/' target='_blank'>here</a>. Our club is affiliated with CHRVA in order to provide the best experience possible for our athletes in clinics and tournaments!</p>"
        }
      }, {
        "@type": "Question",
        "name": "What is AVCA?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text":"<p>The American Volleyball Coaches Association <a href='https://www.avca.org/' target='_blank'>(AVCA)</a> is an organization dedicated to the providing resources and training to both coaches and athletes in order to elevate the volleyball experience. By being affiliated with AVCA, we can leverage a vast number of resources to help our players achieve their goals and in particular, facilitate and improve the college recruiting process.</p>"
        }
      }, {
        "@type": "Question",
        "name": "Do you provide refunds if I can't make my clinic?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text":"<p>If you are unable to make your clinic, please contact us. We understand we are living in a time with a lot of circumstances out of our control, so we will work with people on a case by case basis.</p>"
        }
      }
    }]
  }`

const useStyles = makeStyles((theme) => ({
    root: {
      boxShadow: 'none',
      borderBottom: '1px solid #F78D1F'
    }
  }));

export default function FAQ(){

    const classes = useStyles();

    return (
        <Layout>
        <SEO title="FAQ" structuredData={structuredData}/>
        <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
            <Grid item style={{padding: '2rem'}} xs={12}>
                <Typography variant="h3" style={{color: '#243E83'}}>You've got Questions?</Typography>
                <Typography variant="h4"><i>We've got Answers!</i></Typography>
            </Grid>
            <Grid item style={{padding: '1rem', width: '90%'}} container direction="column" spacing={4} xs={12}>
                {FAQData.map((faq) =>{
                    return(<Grid item><Accordion className={classes.root}>
                    <AccordionSummary
                        expandIcon={<AddCircleIcon style={{color: "#243E83"}}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography variant="h4"><i>"{faq.question}"</i></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="paragraph">
                            {faq.answer()}
                        </Typography>
                    </AccordionDetails>
                    </Accordion></Grid>)
                })}
            </Grid>
        </Grid>
        </Layout>
    )
}



// Q: Do you provide refunds if I can’t make my clinic?
// A: If you are unable to make your clinic, please contact us. We understand we are living in a time with a lot of circumstances out of our control, so we will work with people on a case by case basis. 


// Q: What is AVCA?
// A: AVCA is the American Volleyball Coaches Association. By being affiliated with AVCA, we can provide better resources for recruiting to our players. 
